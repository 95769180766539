import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  setVehicleConnectionConfirmationPending,
  submitConnectSmartcar,
} from '@/actions/vehicleAuth'
import Smartcar from '@smartcar/auth'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'
import { useCountry } from '@/hooks'
import {
  Button,
  CardAction,
  CardActions,
  Icon,
  Sheet,
  Text,
} from '@/components'
import { isProduction } from '@/utils/appUtilities'
import LinkDiagram from 'features/logos/LinkDiagram'
import OptiwattIconLogo from '@/app/img/logo-icon-colored-filled.webp'
import VehicleConnectionPitch from '@/app/components/VehicleConnectionPitch/VehicleConnectionPitch'
import { useLocation } from 'react-router-dom'
import { useWhiteLabelData } from '@/hooks/whitelabel/useWhiteLabelData'

const SCOPE: string[] = [
  'read_engine_oil',
  'required:read_battery',
  'read_charge',
  'control_charge',
  'read_fuel',
  'read_location',
  'read_odometer',
  'read_tires',
  'required:read_vehicle_info',
  'read_vin',
  'read_charge_events',
]

const FORCED_US_SMARTCAR_MAKES: string[] = ['NISSAN', 'VOLKSWAGEN']

interface VehicleMake {
  name: string
  smartcarMake: string
}

interface ConnectSmartCarProps {
  vehicleMake: VehicleMake
  onCancel: () => void
}

const loadVehicleImage = async (vehicleMakeName: string): Promise<string> => {
  const basePath = `/dashboard/img/brands/${vehicleMakeName
    .toLowerCase()
    .replace(' ', '_')}`
  const extensions = ['svg', 'webp', 'png', 'jpg']

  for (const ext of extensions) {
    const path = `${basePath}.${ext}`
    const isValid = await imageExists(path)
    if (isValid) {
      return path
    }
  }

  return '/dashboard/img/brands/default.svg'
}

const imageExists = (src: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const img = new Image()
    img.onload = () => resolve(true)
    img.onerror = () => resolve(false)
    img.src = src
  })
}

function BuiltWithSmartcarSheet() {
  return (
    <Sheet color="green-100">
      <div className="flex items-center">
        <Icon name="SteeringWheel" className="w-4 h-4 mr-2" />
        <Text variant="body2" className="text-green-500">
          BUILT WITH SMARTCAR
        </Text>
      </div>
    </Sheet>
  )
}

const ConnectSmartCar: React.FC<ConnectSmartCarProps> = ({ vehicleMake }) => {
  const [smartcar, setSmartcar] = useState<Smartcar | null>(null)
  const country = useCountry()
  const isMobile = isNativeMobile()
  const dispatch = useDispatch()
  const isOnboarding = useLocation().pathname.includes('onboarding')
  const [media, setMedia] = useState<string>('')
  const { whiteLabelData } = useWhiteLabelData()

  useEffect(() => {
    const onSmartcarAuthComplete = (
      _err: Error | null,
      code: string | null,
    ) => {
      if (code) {
        dispatch(
          setVehicleConnectionConfirmationPending({
            pending: true,
            make: vehicleMake.name,
          }),
        )
        dispatch(submitConnectSmartcar(code))
      } else {
        // Show errors
      }
    }

    setSmartcar(
      new Smartcar({
        clientId: process.env.REACT_APP_SMARTCAR_CLIENT_ID!,
        redirectUri: process.env.REACT_APP_SMARTCAR_REDIRECT_URI!,
        scope: SCOPE,
        testMode: !isProduction,
        onComplete: onSmartcarAuthComplete,
        mode: isProduction ? 'live' : 'simulated',
      }),
    )
  }, [dispatch, vehicleMake])

  const getSmartcarFlags = (): string[] => {
    const flags: string[] = []
    if (
      country === 'CA' &&
      !FORCED_US_SMARTCAR_MAKES.includes(vehicleMake.smartcarMake)
    ) {
      flags.push('country:CA')
    }
    return flags
  }

  const handleClick = () => {
    const state = []
    const urlSearchParams = new URLSearchParams(window.location.search)
    if (urlSearchParams.get('onboarding') === 'true') {
      state.push('onboarding')
    }
    if (window.location.pathname.includes('ava')) {
      state.push('ava')
    }
    const stateString = state.join(',')
    if (isMobile) {
      const href = smartcar?.getAuthUrl({
        vehicleInfo: { make: vehicleMake.smartcarMake },
        flags: getSmartcarFlags(),
        state: stateString,
      })
      if (href) {
        window.location.href = href
      }
    } else {
      smartcar?.openDialog({
        vehicleInfo: { make: vehicleMake.smartcarMake },
        flags: getSmartcarFlags(),
        state: stateString,
      })
    }
  }

  useEffect(() => {
    const loadImage = async () => {
      const mediaPath = await loadVehicleImage(vehicleMake.name)
      setMedia(mediaPath)
    }
    loadImage()
  }, [vehicleMake.name])

  const logos = [OptiwattIconLogo, media]

  if (whiteLabelData?.largeBrandLogo) {
    logos.unshift(whiteLabelData.largeBrandLogo)
  }

  return (
    <>
      <LinkDiagram
        logos={logos}
        widthOverride="tall"
        logoClassName="min-h-[20px]"
      />
      <div className="flex items-center justify-center w-full mt-4 mb-10">
        <BuiltWithSmartcarSheet />
      </div>
      <VehicleConnectionPitch make={vehicleMake.name} />
      <CardActions>
        <CardAction type="primary" className="md:mt-5">
          <Button id="next-button" onClick={handleClick}>
            {isOnboarding ? 'Get Started' : 'Connect'}
          </Button>
        </CardAction>
      </CardActions>
    </>
  )
}

export default ConnectSmartCar
