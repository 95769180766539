import React from 'react'
import theme from '../../../../v2/theme'
import { ThemeProvider } from '@material-ui/core/styles'
import ConnectVehicleSelector from './ConnectVehicleSelector'

export interface Props {
  onClose?: () => void
  onBackClick?: () => void
  onFinish?: () => void
  titleStyle?: string
  selectorAreaStyle?: string
}

export default ({
  onClose = () => {},
  onBackClick,
  onFinish = () => {},
  titleStyle,
  selectorAreaStyle,
}: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <ConnectVehicleSelector
        onBackClick={onBackClick}
        onFinish={onFinish}
        titleStyle={titleStyle}
        selectorAreaStyle={selectorAreaStyle}
      />
    </ThemeProvider>
  )
}
