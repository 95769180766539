import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  resetVehicleAuthErrors,
  setVehicleConnectionConfirmationPending,
} from '@/actions/vehicleAuth'
import { useGetMakes } from '@/types/vehicleBrands'
import ConnectSmartCar from '../smartcar/ConnectSmartCar'
import BrandSelector from '../../BrandSelector'
import ConnectVehicleConfirmation from '../ConnectVehicleConfirmation'
import { logEvent } from '@/logging'
import { useNavigation } from '@/app/hooks'
import { useRouteMatch } from 'react-router-dom'
import { useFeatureFlag } from '@/hooks/useFeatureFlag'

// @todo:
//   has_started_smartcar_flow on profile model: bool
//   then dispatch to update profile

const ConnectVehicleSelector = ({
  onBackClick,
  onFinish,
  titleStyle,
  selectorAreaStyle,
}) => {
  const { url } = useRouteMatch()
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const vehicleMakes = useGetMakes()
  const searchParams = new URLSearchParams(window.location.search)

  const { vehicleConnectionConfirmationPending, errors } = useSelector(
    (state) => state.vehicleAuth,
  )
  const [selectedApi, setSelectedApi] = useState('')
  const [selectedMake, setSelectedMake] = useState('')

  const fordOauth = useFeatureFlag({
    flag: 'ford_oauth',
    experimentalValue: true,
  })

  useEffect(() => {
    logEvent('viewConnectVehicleSelector')
  }, [])

  const setSmartcarDialog = (make) => {
    setSelectedApi('smartcar')
    setSelectedMake(make)
    logEvent('startSmartcarLoginFlow')
    dispatch(resetVehicleAuthErrors())
  }

  const reset = () => {
    setSelectedApi('')
    setSelectedMake('')
  }

  if (vehicleConnectionConfirmationPending.pending) {
    return (
      <ConnectVehicleConfirmation
        onReset={reset}
        onConfirmed={() => {
          onFinish()
          dispatch(
            setVehicleConnectionConfirmationPending({
              pending: false,
              make: vehicleConnectionConfirmationPending.make,
            }),
          )
        }}
      />
    )
  } else if (selectedApi === 'smartcar') {
    return <ConnectSmartCar vehicleMake={selectedMake} onCancel={reset} />
  }

  const handleClick = (make) => {
    const searchParamsString = searchParams.size
      ? `?${searchParams.toString()}`
      : ''
    switch (true) {
      case make.unsupported: {
        navigation.push(`${url}/ev-apology/${make.name}`)
        return
      }
      case make.name === 'Tesla': {
        logEvent('startTeslaLoginFlow', { metadata: { make: make.name } })
        navigation.push(`${url}/tesla${searchParamsString}`)
        dispatch(resetVehicleAuthErrors())
        return
      }
      case make.name === 'Ford' && fordOauth: {
        logEvent('startFordLoginFlow', { metadata: { make: make.name } })
        navigation.push(`${url}/ford${searchParamsString}`)
        dispatch(resetVehicleAuthErrors())
        return
      }
      default: {
        setSmartcarDialog(make)
      }
    }
  }

  // @ todo add back button to tesla by adding component that automatically has a back button
  return (
    <BrandSelector
      id="connect-vehicle-selector"
      brandList={Object.values(vehicleMakes)}
      onButtonSelect={handleClick}
      titleStyle={titleStyle}
      selectorAreaStyle={selectorAreaStyle}
      includeInsetBottom={false}
      handleGoBack={onBackClick}
      brandType="vehicle"
    />
  )
}

export default ConnectVehicleSelector
