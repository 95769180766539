import { useAppSelector } from '.'
import { getCountriesForTimezone } from 'countries-and-timezones'

const useCountry = () => {
  const country = useAppSelector(
    (state) => state.user.user?.profile?.home?.state?.country,
  )
  if (country) {
    return country
  }

  // If the user has not entered their address, we try to guess it from the timezone
  return getCountryFromLocalTimezone()
}

const getCountryFromLocalTimezone = () => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const countries = getCountriesForTimezone(timezone)
    if (countries.length > 0) {
      return countries[0].id
    }
    return null
  } catch (e) {
    return null
  }
}

export default useCountry
